import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,5,6,2,4];

export const dictionary = {
		"/": [7],
		"/(user)/admin/manage-registrations": [~23,[5,6]],
		"/(payment)/checkout/cancel": [19],
		"/(payment)/checkout/success": [20],
		"/(auth)/confirm-email-verification/[token]": [~8],
		"/(events)/events": [16,[2]],
		"/(events)/e/[eventName]": [~14,[2]],
		"/(events)/e/[eventName]/registration": [~15,[2],[,3]],
		"/(faq)/faq": [~18],
		"/(auth)/forgot-password": [9],
		"/(auth)/login": [~10],
		"/(racetracks)/racetracks": [21,[4]],
		"/(racetracks)/racetracks/[racetrack]": [22,[4]],
		"/(auth)/register": [~11],
		"/(auth)/reset-password-email-sent": [13],
		"/(auth)/reset-password/[token]": [~12],
		"/(events)/results": [~17,[2]],
		"/(user)/user": [~24,[5]],
		"/(user)/user/account/preferences": [~25,[5]],
		"/(user)/user/account/profile": [~26,[5]],
		"/(user)/user/my-cars": [~27,[5]],
		"/(user)/user/my-events": [~28,[5]],
		"/(user)/user/my-registrations": [~29,[5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';