import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

Sentry.init({
	dsn: 'https://84e77ab0dd6ee371c027944723ee7de3@o4506650453082112.ingest.sentry.io/4506650482442240',

	tracesSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	// integrations: [Sentry.replayIntegration()],

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0
});

Sentry.init({
	dsn: 'https://84e77ab0dd6ee371c027944723ee7de3@o4506650453082112.ingest.us.sentry.io/4506650482442240',
	// integrations: [
	// 	// Add browser profiling integration to the list of integrations
	// 	new Sentry.BrowserProfilingIntegration()
	// ],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
	// Set profilesSampleRate to 1.0 to profile every transaction.
	// Since profilesSampleRate is relative to tracesSampleRate,
	// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
	// results in 25% of transactions being profiled (0.5*0.5=0.25)
	profilesSampleRate: 1.0
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
/**
 * Handles client errors and captures them using Sentry.
 * @param error - The error object.
 * @param event - The event object.
 * @param status - The status code.
 * @returns An object containing the error message and error ID.
 */
export const sentryClientErrorHandler: HandleClientError = ({ error, event, status }) => {
	const errorId = crypto.randomUUID();
	Sentry.captureException(error, {
		contexts: { sveltekit: { event, errorId, status } }
	});

	return {
		message: "An unexpected error occured. We're working on it",
		errorId
	};
};

export const handleError = Sentry.handleErrorWithSentry(sentryClientErrorHandler);
